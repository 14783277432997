// import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

import PersonIcon from '@mui/icons-material/Person';

import Xarrow, { useXarrow, xarrowPropsType } from 'react-xarrows';
import { generateGuid } from '../utils/utils';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import React from 'react';
import { Button } from './button';

type Gender = 'Male' | 'Female';

interface FamilyNode {
  id: string;
  firstName?: string;
  gender?: Gender;
  children?: FamilyNode[];
  marriedTo?: FamilyNode;
}

const createNewNode = (gender?: Gender) => {
  return {
    id: generateGuid(),
    gender: gender,
  };
};

export const TreeView = () => {
  let initialNodeData = getLocalStorageNodeData();

  if (!initialNodeData) {
    initialNodeData = createNewNode();
    setLocalStorageNodeData(initialNodeData);
  }

  return <Tree initialNodeData={initialNodeData} />;
};

const InitialNode: FamilyNode = {
  id: generateGuid(),
};

// This style of node representation will not work becaue once you have 2 lineages it will fail to represent it appropriately.
// TODO: refactor this as a map of nodes + map of connections between nodes
// Then you will be able to build a view representation by doing a parents BFS and a children BFS from the central node.
// Depending on who is currently the central node, we must rebuild the entire tree every time from that individual
const AblimitNode: FamilyNode = {
  id: generateGuid(),
  firstName: 'Ablimit',
  gender: 'Male',
  marriedTo: {
    id: generateGuid(),
    firstName: 'Reyhan',
    gender: 'Female',
  },
  children: [
    {
      id: generateGuid(),
      firstName: 'Parhat',
      gender: 'Male',
      marriedTo: {
        id: generateGuid(),
        firstName: 'Adila',
        gender: 'Female',
      },
      children: [
        {
          id: generateGuid(),
          firstName: 'Faruk',
          gender: 'Male',
        },
        {
          id: generateGuid(),
          firstName: 'Elzat',
          gender: 'Male',
        },
        {
          id: generateGuid(),
          firstName: 'Eldana',
          gender: 'Female',
        },
      ],
    },
    {
      id: generateGuid(),
      firstName: 'Mutellip',
      gender: 'Male',
      marriedTo: {
        id: generateGuid(),
        firstName: 'Baghdat',
        gender: 'Female',
      },
      children: [
        {
          id: generateGuid(),
          firstName: 'Chughluk',
          gender: 'Female',
        },
        {
          id: generateGuid(),
          firstName: 'Khunduz',
          gender: 'Female',
        },
      ],
    },
    {
      id: generateGuid(),
      firstName: 'Han',
      gender: 'Female',
      marriedTo: {
        id: generateGuid(),
        firstName: 'Kurbanjan',
        gender: 'Male',
      },
      children: [
        {
          id: generateGuid(),
          firstName: 'Ferdon',
          gender: 'Male',
        },
      ],
    },
  ],
};

const SelectedNodeIdContext = React.createContext<{
  selectedNodeId: string | null;
  setSelectedNodeId: Dispatch<SetStateAction<string | null>>;
}>({
  selectedNodeId: null,
  setSelectedNodeId: () => undefined,
});

const CurrentNodeDataContext = React.createContext<{
  currentNodeData: FamilyNode | null;
  setCurrentNodeData: Dispatch<SetStateAction<FamilyNode>>;
}>({
  currentNodeData: AblimitNode,
  setCurrentNodeData: () => undefined,
});

const getLocalStorageNodeData = () => {
  const nodeData = localStorage.getItem('nodeData');

  if (nodeData) {
    return JSON.parse(nodeData) as FamilyNode;
  }

  return null;
};

const setLocalStorageNodeData = (nodeData: FamilyNode | null) => {
  localStorage.setItem('nodeData', JSON.stringify(nodeData));
};

const Tree = ({ initialNodeData }: { initialNodeData: FamilyNode }) => {
  const [currentSelectedNodeId, setCurrentSelectedNodeId] = useState<
    string | null
  >(null);

  const [currentNodeData, setCurrentNodeData] =
    useState<FamilyNode>(initialNodeData);

  setLocalStorageNodeData(currentNodeData);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateXarrow = useXarrow();

  useEffect(() => {
    updateXarrow();
  }, [currentNodeData]);

  return (
    <CurrentNodeDataContext.Provider
      value={{
        currentNodeData: currentNodeData,
        setCurrentNodeData: setCurrentNodeData,
      }}
    >
      <SelectedNodeIdContext.Provider
        value={{
          selectedNodeId: currentSelectedNodeId,
          setSelectedNodeId: setCurrentSelectedNodeId,
        }}
      >
        <TreeContainer id="tree-container">
          <TreeToolbar updateXarrow={updateXarrow} />
          {currentNodeData && <TreeNode node={currentNodeData} />}
        </TreeContainer>
      </SelectedNodeIdContext.Provider>
    </CurrentNodeDataContext.Provider>
  );
};

const TreeToolbar = ({ updateXarrow }: { updateXarrow: () => void }) => {
  const { setCurrentNodeData } = useContext(CurrentNodeDataContext);

  return (
    <TreeToolbarContainer>
      <Button
        onClick={() => {
          updateXarrow();
        }}
      >
        Rerender
      </Button>
      <Button
        onClick={() => {
          setCurrentNodeData(InitialNode);
        }}
      >
        Clear Data
      </Button>
      <Button
        onClick={() => {
          setCurrentNodeData(AblimitNode);
        }}
      >
        Set Ablimits Data
      </Button>
    </TreeToolbarContainer>
  );
};

const TreeToolbarContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  column-gap: 8px;
`;

// TODO: Subscribe to photo, gender, or name changes of the node
const TreeNodeCircle = ({ node }: { node: FamilyNode }) => {
  const { selectedNodeId, setSelectedNodeId } = useContext(
    SelectedNodeIdContext
  );

  const isThisNodeSelected = node.id === selectedNodeId;

  const onNameInput: React.FormEventHandler<HTMLDivElement> = (
    e: FormEvent<HTMLDivElement>
  ) => {
    console.log(e.currentTarget.textContent);
  };

  return (
    <TreeNodeCircleContainer>
      <TreeNodeCircleDiv
        gender={node.gender}
        id={node.id}
        isSelected={isThisNodeSelected}
        onClick={() => {
          setSelectedNodeId(node.id);
        }}
      >
        <PersonIcon style={{ width: '48px', height: '48px', color: 'white' }} />
      </TreeNodeCircleDiv>
      {isThisNodeSelected ? (
        <TreeNodeNameInput node={node} />
      ) : (
        node.firstName && (
          <TreeNodeLabel onInput={onNameInput}>{node.firstName}</TreeNodeLabel>
        )
      )}
      {isThisNodeSelected && <TreeNodeEditButtons node={node} />}
    </TreeNodeCircleContainer>
  );
};

const TreeNodeNameInput = ({ node }: { node: FamilyNode }) => {
  const { setCurrentNodeData } = useContext(CurrentNodeDataContext);

  return (
    <TreeNodeInput
      value={node.firstName}
      placeholder={'name'}
      onInput={e => {
        let newName: string | null = e.currentTarget.value;

        if (newName === '') {
          newName = null;
        }

        setCurrentNodeData(currentNodeData => ({
          ...currentNodeData,
          ...setNodeFirstName(currentNodeData, node.id, newName),
        }));
      }}
    />
  );
};

const TreeNodeInput = styled.input`
  position: absolute;
  left: 50%;
  top: calc(100% + 4px);
  transform: translateX(-50%);
  z-index: 50;
  background-color: white;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid lightgray;

  min-width: 32px;

  font-size: 16px;
  font-family: 'Roboto';
  width: 64px;
  box-sizing: border-box;
  text-align: center;
`;

const TreeNodeLabel = styled.div`
  position: absolute;
  left: 50%;
  top: calc(100% + 4px);
  transform: translateX(-50%);
  z-index: 50;
  background-color: white;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid lightgray;

  min-width: 32px;
`;

const TreeNodeEditButtons = ({ node }: { node: FamilyNode }) => {
  const { currentNodeData, setCurrentNodeData } = useContext(
    CurrentNodeDataContext
  );

  if (!currentNodeData) {
    return null;
  }

  const onAddChild = () => {
    const nodeInMainState = findNodeInTree(node.id, currentNodeData);

    if (nodeInMainState) {
      const updatedNodeTree = addChildToNode(
        currentNodeData,
        node.id,
        createNewNode()
      );

      setCurrentNodeData(currentNodeData => ({
        ...currentNodeData,
        ...updatedNodeTree,
      }));
    }
  };

  const onAddPartner = () => {
    const nodeInMainState = findNodeInTree(node.id, currentNodeData);

    if (nodeInMainState) {
      const updatedNodeTree = addPartnerToNode(
        currentNodeData,
        node.id,
        createNewNode(node.gender === 'Male' ? 'Female' : 'Male')
      );

      setCurrentNodeData(currentNodeData => ({
        ...currentNodeData,
        ...updatedNodeTree,
      }));
    }
  };

  const onGenderSelect = (e: FormEvent<HTMLSelectElement>) => {
    let gender: null | Gender;
    if (e.currentTarget.value === 'Unknown') {
      gender = null;
    } else {
      gender = e.currentTarget.value as Gender;
    }
    setCurrentNodeData(currentNodeData => ({
      ...currentNodeData,
      ...setNodeGender(currentNodeData, node.id, gender),
    }));
  };

  return (
    <TreeNodeEditButtonsContainer>
      <TreeNodeEditButton onClick={onAddChild}>Add Child</TreeNodeEditButton>
      <TreeNodeEditButton onClick={onAddPartner}>
        Add Partner
      </TreeNodeEditButton>
      <GenderSelect value={node.gender} onInput={onGenderSelect}>
        <option value={undefined}>Gender - Unknown</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </GenderSelect>
    </TreeNodeEditButtonsContainer>
  );
};

const GenderSelect = styled.select`
  border-radius: 4px;
  height: 26px;
  border: none;

  font-family: 'Roboto';
  font-size: 16px;
`;

// Performs an operation on a node and returns the updated tree from the root node
const operateOnNode = (
  rootNode: FamilyNode,
  nodeId: string,
  operation: (node: FamilyNode) => void
): FamilyNode => {
  const nodesToGoThrough: FamilyNode[] = [rootNode];

  // Find the node..
  while (nodesToGoThrough.length > 0) {
    const currentNode = nodesToGoThrough.pop();

    if (!currentNode) {
      break;
    }

    if (currentNode.id === nodeId) {
      operation(currentNode);
      break;
    }

    if (currentNode.marriedTo) {
      nodesToGoThrough.push(currentNode.marriedTo);
    }

    if (currentNode.children) {
      currentNode.children.forEach(currentNodeChildNode => {
        nodesToGoThrough.push(currentNodeChildNode);
      });
    }
  }

  return rootNode;
};

const setNodeFirstName = (
  rootNode: FamilyNode,
  nodeId: string,
  newName: string | null
) => {
  return operateOnNode(rootNode, nodeId, node => {
    if (!newName) {
      node.firstName = undefined;
    } else {
      node.firstName = newName;
    }
  });
};

const setNodeGender = (
  rootNode: FamilyNode,
  nodeId: string,
  gender: Gender | null
) => {
  return operateOnNode(rootNode, nodeId, node => {
    if (!gender) {
      node.gender = undefined;
    } else {
      node.gender = gender;
    }
  });
};

const addChildToNode = (
  rootNode: FamilyNode,
  nodeId: string,
  childNode: FamilyNode
): FamilyNode => {
  return operateOnNode(rootNode, nodeId, node => {
    if (!node.children) {
      if (!node.marriedTo) {
        node.marriedTo = createNewNode();
      }

      node.children = [childNode];
    } else {
      node.children.push(childNode);
    }
  });
};

const addPartnerToNode = (
  rootNode: FamilyNode,
  nodeId: string,
  partnerNode: FamilyNode
): FamilyNode => {
  return operateOnNode(rootNode, nodeId, node => {
    if (!node.marriedTo) {
      node.marriedTo = partnerNode;
    }
  });
};

const findNodeInTree = (
  nodeId: string,
  currentNode: FamilyNode
): FamilyNode | null => {
  if (currentNode.id === nodeId) {
    return currentNode;
  }

  if (currentNode.children) {
    for (const childNode of currentNode.children) {
      const foundChild = findNodeInTree(nodeId, childNode);
      if (foundChild) {
        return foundChild;
      }
    }
  }

  return null;
};

const TreeNodeEditButton = styled.div`
  cursor: pointer;
  padding: 4px;
  background-color: white;
  border: gray;
  border-radius: 4px;

  user-select: none;

  &:hover {
    opacity: 0.5;
  }
`;

const TreeNodeEditButtonsContainer = styled.div`
  position: absolute;
  z-index: 100;
  column-gap: 4px;
  display: flex;
  flex-direction: row;

  left: 50%;
  transform: translate(-50%, calc(100% + 48px));
  white-space: nowrap;

  background-color: darkgray;
  padding: 4px;
  border-radius: 8px;
`;

// TODO: Optimize in the future so each tree node can subscribe to changes to each node
const TreeNode = ({
  node,
  parentMarriagePointId,
}: {
  node: FamilyNode;
  parentMarriagePointId?: string;
}) => {
  const thisNode = <TreeNodeCircle node={node} />;

  let marriagePointId = '';
  if (node.marriedTo) {
    marriagePointId = getMarriagePointId(node.id, node.marriedTo.id);
  }

  const childrenGroup = node.children && (
    <ChildrenGroup
      childNodes={node.children}
      parentMarriagePointId={marriagePointId}
    />
  );

  const marriageRow = node.marriedTo ? (
    <>
      {thisNode}
      <MarriagePoint id={getMarriagePointId(node.id, node.marriedTo.id)} />
      <TreeNodeCircle node={node.marriedTo} />
      <FamilyConnection
        start={node.id}
        end={getMarriagePointId(node.id, node.marriedTo.id)}
      />
      <FamilyConnection
        start={node.marriedTo.id}
        end={getMarriagePointId(node.id, node.marriedTo.id)}
      />
    </>
  ) : (
    thisNode
  );

  return (
    <TreeNodeContainer>
      {parentMarriagePointId && (
        <FamilyConnection
          start={parentMarriagePointId}
          end={node.id}
          startAnchor={'bottom'}
          endAnchor={'top'}
          path={'smooth'}
          curveness={0.6}
        />
      )}
      <MarriageRow>{marriageRow}</MarriageRow>
      {childrenGroup}
    </TreeNodeContainer>
  );
};

const getMarriagePointId = (leftNodeId: string, rightNodeId: string) => {
  return `marriage-point-${leftNodeId}+${rightNodeId}`;
};

const FamilyConnection = (props: xarrowPropsType) => {
  return <Xarrow {...props} showHead={false} color="gray" zIndex={0} />;
};

const ChildrenGroup = ({
  childNodes,
  parentMarriagePointId,
}: {
  childNodes: FamilyNode[];
  parentMarriagePointId: string;
}) => (
  <ChildrenContainer>
    {childNodes.map((childNode, index) => (
      <TreeNode
        key={index}
        node={childNode}
        parentMarriagePointId={parentMarriagePointId}
      />
    ))}
  </ChildrenContainer>
);

const MarriageRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
  justify-content: center;
`;

const MarriagePoint = styled.div`
  width: 16px;
  height: 16px;
  background-color: gray;
  border-radius: 16px;
`;

const TreeNodeContainer = styled.div`
  display: inline-block;
  text-align: center;
`;

const TreeNodeCircleContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ChildrenContainer = styled.div`
  padding-top: 64px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  column-gap: 64px;
`;

const TreeNodeCircleDiv = styled.div<{
  gender?: Gender;
  isSelected: boolean;
}>`
  background-color: white;
  width: 64px;
  height: 64px;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${props =>
    props.gender
      ? props.gender === 'Female'
        ? 'pink'
        : 'lightblue'
      : 'lightgray'};

  ${props => props.isSelected && 'outline: 4px solid #C1AC95;'}

  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const TreeContainer = styled.div`
  text-align: center;
  display: inline-block;
  position: relative;
  padding: 64px;

  left: 5%;
  top: 5%;
  box-sizing: border-box;

  width: 90%;
  height: 90%;
  overflow: auto;
  border: 1px solid white;
`;
