import React from 'react';
import styled from 'styled-components';

export const Button = ({
  children,
  onClick,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => <ButtonDiv onClick={onClick}>{children}</ButtonDiv>;

const ButtonDiv = styled.div`
  padding: 16px;
  display: inline-block;
  border-radius: 4px;
  color: white;
  background-color: ${props => props.theme.s3};

  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
